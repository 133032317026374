import React, { useState, useEffect } from "react";
import "./App.css"; // Ensure this file exists and contains the required styles

import experience from "./experience.json";
import education from "./education.json";
import topSkills from "./top-skills.json";

const App = () => {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    const fetchLogo = async () => {
      const logo = await import("./favicon.ico");
      setLogo(logo.default);
    };

    fetchLogo();
  }, []);

  return (
    logo && (
      <div className="resume">
        {/* Header section */}
        <header className="resume-header">
          <h1>Vignesh Prasad</h1>
          <p>
            <h2>Full Stack Developer</h2>
            <span className="header-key">
              <a href="mailto:mail@vigneshprasad.com">mail@vigneshprasad.com</a>
            </span>
            <span className="header-key">
              <a
                href="https://www.linkedin.com/in/vignesh-prasad"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </span>
          </p>
        </header>

        {/* Summary section */}
        <section className="resume-summary">
          <p>
            Full Stack Developer specializing in React, Node.js, MongoDB, Docker
            & Cloud services.
          </p>
          <p>
            Certified in AWS and capable of designing, developing, deploying,
            managing and operating highly available, scalable and secure fault
            tolerant systems on the cloud across Availability Zones and Regions.
          </p>
          <p>
            Certified ScrumMaster® capable of providing training in, as well as
            implementing Scrum practices for product development.
          </p>
        </section>

        {/* Experience section */}
        <section className="resume-timeline">
          <h2>Experience</h2>
          {experience.map((exp, i) => (
            <div className="timeline-block" key={i}>
              <div className="timeline-date">{exp.dates}</div>
              <div className="timeline-content">
                <h3>{exp.title}</h3>
                <p>
                  <b>{exp.placement}</b>
                </p>
                {exp.pointers.map((pointer, j) => (
                  <p key={`${i}-${j}`} className="timeline-pointer">
                    {pointer}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </section>

        {/* Education section */}
        <section className="resume-timeline resume-education">
          <h2>Education & Qualifications</h2>
          {education.map((qualification, i) => (
            <div className="timeline-block" key={i}>
              <div className="timeline-date">{qualification.dates}</div>
              <div className="timeline-content">
                <h3>{qualification.title}</h3>
                <p>
                  <b>{qualification.institution}</b>
                </p>
                <p>
                  {qualification.field && <span>{qualification.field}</span>}
                  {qualification.url && (
                    <a
                      className="certificate-link"
                      href={qualification.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View certificate
                    </a>
                  )}
                </p>
              </div>
            </div>
          ))}
        </section>

        {/* Skills section */}
        <section className="resume-skills">
          <h2>Top Skills</h2>
          <ul>
            {topSkills.map((skill, i) => (
              <li key={i}>
                <a href={skill.url} target="_blank" rel="noopener noreferrer">
                  {skill.tag}
                </a>
              </li>
            ))}
          </ul>
        </section>
      </div>
    )
  );
};

export default App;
